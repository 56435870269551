.App {
  background-image: url('https://ournoel.rocks/christmas_tree_and_empty_sleigh.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: hidden;
}


h1, h2, h3, .address {
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 4px 4px 4px rgba(0, 0, 0, 0.871);
}

h1 {
  font-family: "Comic Neue", cursive;
  font-size: 6rem;
  font-weight: 700;
  font-style: normal;
}

h2.lead {
  font-size: 4rem;
  font-family: 'Comic Neue', cursive;
  font-style: normal;
  font-weight: 400;
}

h3.follow {
  font-family: 'Comic Neue', cursive;
  font-style: normal;
  font-weight: 400;
}

.secret-santa-card {
  width: 300px;
  height: 500px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.583);
  background-color: rgba(255, 255, 255, 0.850);
}

.pere-noel-card {
  width: 300px;
  height: 500px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.583);
  background-color: rgba(255, 255, 255, 0.850);
}

.food-card {
  width: 300px;
  height: 500px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.583);
  background-color: rgba(255, 255, 255, 0.850);
}

/* Add this to your App.css */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}


.modal-open .App {
  filter: blur(5px);
}

.modal-dialog {
  max-width: 600px;
}

.modal-body {
  font-family: 'Arvo', serif;
}

.modal-body label {
  font-family: 'Arvo', serif;
  padding-right: 0.5rem;
}

.modal-body input,
.modal-body textarea,
.modal-body button {
  font-family: 'Arvo', serif;
}

.modal-allergies {
  font-family: 'Comic Neue', cursive;
  margin-top: 1rem;
}

.modal-perenoel {
  font-family: 'Comic Neue', cursive;
  margin-top: 1rem;
}

.modal-text {
  font-size: 1rem;
}

.modal-small {
  font-size: 0.8rem;
}
